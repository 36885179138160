/*------------- containers-----------------*/

.righthalf {

    position: relative;
  }

.lefthalf {
    width: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background: var(  --color-darkblue);
    padding: 10px 10px;
    box-shadow: 8px 8px 12px 0 rgb(83 97 255 / 12%), -8px -8px 12px 0 hsl(0deg 0% 100% / 50%);
    z-index: 20;
    overflow:auto
  
  }

  .leftcontainer {
    position: relative;  
    max-width: 450px;
    max-height: 100vh;

  }

  .blackcontainer {
    background: black;
  }

  .reset {
    margin: 30px 0px;
    left: 0%;
  }

  .highlightbox{
    background: var(--color-primary);
    border-radius: 5px;
  }

  .highlightbox h3 {
    color: white;
  }
 

  @media screen and (min-width: 768px) {

  .lefthalf.bluecontainer {
  margin-top: 8 * 10px;
   }


 .righthalf {
  width: 100%;

 }

 .righthalf--special {
     overflow: auto;
 }



}


  @media screen and (min-width: 1024px) {
    .splithalf {
      height: 100vh;
      display: flex;
      flex-direction: row;
    }
  
    .splithalf-special {
      margin: 40px;
      align-items: flex-start;
      align-content: stretch;
      justify-content: center;
    }
  
    .lefthalf {
      width: 45%;
      max-height: 100vh;
      padding: 20px 10px;
    }

    .lefthalf--special{
        width:60%;
        overflow:auto;
    }

    .reset {
      margin: 30px 0px;
      left: 5%;
    }

.overflow {
  overflow-y: auto;
}


  }