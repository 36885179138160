:root {
    --color-primary: #19191d;
    --color-accent: rgba(255, 255, 255, 0.24);
    --color-background: #e0f7fa;
    --color-outline: #1d3557;
    --color-highlight: #06d6a0;
    --color-headings: #f7f8f8;
    --color-secondaryFont: #ffffffb8;
    --color-instructions: #8b8c9b;
    --color-danger: #ed4e50;
    --color-lightblue: #f4f6ff;
    --color-alternative: #bbaeae3d;
    --color-darkblue: #171739;
    --color-fixedblack: black;
    --color-blackover--special: #131316;
    --color-line: #f4f4f9;
  }

  // body {
  //     // background: var(--color-lightblue);
  //     background-image: linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 
  //     247) 
  //     100%);
  // }


  html {
    font-size: 62.5%;
  }
  
  body {
    font-size: 1.6 * 10px;
    line-height: 1.5;
  }
  
  h1,
  h2,
  h3,
  p {
    font-family: Inter, Arial, Helvetica, sans-serif;
    color: var(--color-primary);
    margin-bottom: 1.2 * 10px;
    line-height: 1.5;
  }
  
  .h1 {
    font-size: 3.6 * 10px;
    font-weight: 700;
  }
  
  .h2 {
    font-size: 2.6 * 10px;
  }
  
  .h3 {
    font-size: 2 * 10px;
  }
  
  p {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 25px;
    color: var(--color-primary);
  }

  em {
    color: var(--color-highlight);
    font-style: normal;
    font-weight: 600;
    line-height: 5rem;
  }
/*-----------Links---------------------------*/

a {
    text-decoration: none;
  }
  
  .link-style {
    color: var(--color-blue);
    font-style: normal;
  }
  
  /*---------button---------------------*/
  .btnn {
    line-height: 3.6 * 10px;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 4px;
    border: 0;
    display: inline-block;
    text-decoration: none;
    padding-top: 0.3 * 10px;
    padding-bottom: 0.3 * 10px;
    margin: 1 * 10px 0;
    width: 230px;
    margin-top: 2 * 10px;
    margin-bottom: 2 * 10px;
    cursor: pointer;
    font-size: 14px;
  }

  .btnn--small {
    line-height: 3.6 * 10px;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 4px;
    border: 0;
    display: inline-block;
    text-decoration: none;
    padding-top: 0.3 * 10px;
    padding-bottom: 0.3 * 10px;
    margin: 10px 10px;
    width: auto;
    cursor: pointer;
    // font-size: 11.5px;
    background: black;
    color:white;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 0px 30px 0px 30px;

  }

  .btnn-text {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .btnn-primary {
    background: #5567e7;
    color: var(--color-headings);
  }
  
  
  
  .btnn-secondary {
    background: #ffffff3d;
    border: 1px solid var(--color-outline);
    padding: 2px;
    // padding: 12px;

  }
  
  .btnn-disable {
    background: #5567e7;
    color: var(--color-headings);
    opacity: 0.65;
    cursor: not-allowed;
  }
  
  .btnn-strech {
    width: 26 * 10px;
  }

  .btnn-transparent {
    background: transparent;
    border: 1px solid black;
    color: black;
  }

  .roundButton {
    background: black;
    color: white;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .outlinebutton {
    background-color: white;
    border-radius: 25px;
    padding: 5px 20px;
    outline: 0;
    outline: none;
    border: 0px;
    font-weight: 600;
    
  }

  .outlinebutton--red{
    background: rgb(248, 171, 175);;
    color: rgb(160 75 53);
    font-weight: 600;
  }

  .outlinebutton--grey{
    border: 0.8px solid rgb(95 111 111);
    color: rgb(95 111 111);
    background: aliceblue;
  }


  .outlinebutton--green{
    background: rgb(210, 244, 211);
    color:  rgb(27, 93, 74);
  
  }

  .outlinebutton--blue{
    border: 0.5px solid rgb(96, 117, 198);
    color: rgb(113, 127, 200);
    cursor: pointer;
    font-weight: 700;
  }

  .outlinebutton--blue--selection {
    color: white;
    border: 0.5px solid rgb(96, 117, 198);
    background: rgb(96, 117, 198);
    cursor: pointer;
  }

  .outlinebutton--blue--cursor {
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    .btnn-strech {
      width: 40 * 10px;
    }
  }

/*----------inputs--------------------*/

.label-style {
  font-size: 1.6 * 10px;
  color: var(--color-primary);
  line-height: 2.4 * 10px;
}

.input--custom {
  border: 0.4px solid #cfd7fd;
  padding: 2 * 10px;
  display: block;
  height: 4.5 * 10px;
  border-radius: 7px;
  margin-bottom: 15px;
  width: 100%;
}

.input--small {
  width: 330px;
}

.input--width {
  height: 140px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.input--custom:focus {
  border-color: var(--color-blue);
}

.input-bundle {
  margin-top: 1.6 * 10px;
  margin-bottom: 2.6 * 10px;
}

.input--chat {
  border: 0.4px solid #cfd7fd;
  padding: 2 * 10px;
  display: block;
  height: 80px;
  width: 100%;
  border-radius: 7px;
  margin-bottom: 15px;
  
}

.input--custom::placeholder {
  // border-color: var(--color-highlighttext);
  font-size: small;
}

.input--search {
  position: relative;
  left: 100;
  margin-left: 150px;
  background: #f4f6ff;
  box-shadow: -4px -4px 6px #ffffff, 4px 4px 6px #dde3ff;
  border-radius: 4px;
  width: 500px;
  padding: 2.5px 10px;
}

.input--search:focus {
  // border-color: var(--color-blue);
}

.input--extend {
  width: 800px;
}

.input--height{
  width: 600px;
  height: 280px;

}

.input--width--small{
  width: 400px;
}


.button--blue {
  background: var(--color-lightblue);
  color: black;
  padding: 7px 12px;
  border-radius: 5px;
  // letter-spacing: 1px;
  cursor: pointer;
  outline: none;
  border: 0px;
  margin-right: 15px;
  
}

.submit-button {
  background-color: #333;
  color: #fff;
  padding: 8px;
  border: none;
  height: 50px;
}


@media screen and (min-width: 768px) {
  .input--custom {
    width: 400px;
  }
}


@media screen and (min-width: 1440px) {



  .input--height {
    width: 800px;
  }

  .input--height--small {
    width: 400px;
    height: 280px;
  }
}

/*-----------select--------------------*/
.selectcustom {
  height: 42px;
  width: 400px;
  border: 2px solid #cfd7fd;
  border-radius: 7px;
  outline: 0;
  padding: 5px;
}

@media screen and (min-width: 768px) {
  .selectcustom {
    width: 400px;
  }
}


/*------------- Alert box -------------------------*/

.alertmessage {
    color: var(--color-danger);
    font-size: 1.5 * 10px;
    font-weight: 500;
    padding: 0.5 * 10px;
    padding-bottom: 2.5 * 10px;
}




  /* ------------ Container ---------------------------*/

  .containerbox {
  background-color: var(--color-alternative);
  width: 100%;
  max-width: 600px;
  padding: 100px 50px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px;
  justify-content: space-around;
}


/*------------ Searchbox --------------------------*/

.searchbox {
  border: none;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 10px 50px;
  margin: 25px;
  // width: 100%;
  border-radius: 20px 20px;
  width: 1000px;
  outline: 0;
}

@media screen and (min-width: 768px) {
  .searchbox {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

/*----------- profilepic --------------------------*/

.profilepic {

  border-radius: 50%;
  width: 30px;
  margin-right: 20px;
}

.borderbottom{
  border-bottom: 0.01px solid var(--color-line);
  margin-bottom: 35px;
}


/*------------ Pricing page --------------------------*/

.gridcontainer li {
  list-style:none;
  margin-top: 10px;
  font-style: 500px;
  font-weight:600;
  border-bottom: 0.05px solid var(--color-line);
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: 85%;
}

/*--------- Outline ---------------------------------*/

.hover-outline {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.hover-outline:hover {
  transform: scale(1.1);
}


/*------------ bottom border ------------------------*/

.bb {
border-bottom: 0.2px solid var(--color-alternative);
padding: 5px;
}

/*------------- Circle ----------------------------*/

.CircleClass {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid black; /* Add a black border to make it more prominent */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add a shadow for depth */
}

.CircleClass--red {
  background-color: red;
}

.CircleClass--orange {
  background-color: orange;
}

.CircleClass--green {
  background-color: rgb(83, 202, 83);
}

/* Add hover effect to highlight the error */
.redCircleClass:hover {
  transform: scale(1.1); /* Increase the size on hover */
  transition: transform 0.3s ease-in-out; /* Add a smooth transition */
}

/*------------- List style ------------------------------*/

.instructionlist li {
  padding-bottom: 8px;
}

/*------------- Arrow button -----------------------------*/

.submit--custom {
  outline: 0;
  background: white;
  border: 0px;
  height: 90px;
  width: 90vw;
  max-height: 140px;
  padding: 15px 40px 5px 15px;
  font-size: 16px;
  resize: vertical;
  overflow-y: scroll;
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  bottom: 0;
  margin: 0;
  border-radius: 0px 0px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}

.submit--custom::placeholder {
  opacity: 0.5;
}

@media screen and (min-width: 1440px) {
  .submit--custom {
    width: 650px;
  }

}

.underline-text {
  text-decoration: underline;
}

/*--------------------- Capture Icon ------------------------------------*/

.capture {
  opacity: 0;

}

.capture--red {
  color: rgb(233, 103, 64);
  opacity: 1;

}

.capture--grey {
  color: rgb(171, 164, 162);
  opacity: 1;

}

.capture--green {
  color: rgb(89, 169, 89);
  opacity: 1;

}

/*--------------------- Github style button -------------------------*/@at-root

/* CSS */
.button-4 {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.button-4:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.button-4:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.button-4:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}

/*-------Opacity ---------------------*/

.opacityless{
  opacity:0.2;
}

