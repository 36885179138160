/*--------- horizontal ----------------*/

.flexcolumn 
{
    display: flex;
    flex-direction: column;
}

.flexrow {
    display: flex;
    flex-direction: column;
}

.flexrow--rigid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flexrow--rigid--only {
    display: flex;
    flex-direction: row;

}

.mobile--adjustments {
 justify-content: center;
}

.flex--inline{
    display: inline-flex;
}


.flex--wrap {
    flex-wrap: wrap
}

.flex {
    display: flex;
}



@media screen and (min-width: 1024px) {

    .flexrow {
        flex-direction: row;
    }

    .flexrow--reverse {
        flex-direction: row-reverse
    }
  

}


@media screen and (min-width: 1080px) {
.mobile--adjustments {
    justify-content: flex-start
}
}

.horizontal--center {
    align-items: center;
}


.flex__spaceAround{
    justify-content: space-around
}


.flexcolumn--allcenter {
    justify-content: center;
    align-items: center
}

.alignhorizontal-center {
    align-items: center;
}


.alignhorizontal-wideapart {
    justify-content: space-between;
}

.alignhorizontal-custom {
    align-items: flex-start;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.wideapart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}


@media screen and (min-width: 1024px) {
    .flexend{
        align-items: flex-end;
    }
.flexend--other{
   justify-content: flex-end;

}
}


.topleft{
    position: relative;
    top:-20px;
    left:10px
}

.flexrow--justify--center {
    justify-content: center;
}

/*---------- grid----------------------------*/


.grid {
    display: grid;
  }

.grid--allcenter {
    align-items: center;
} 

.grid--1x2{
    grid-template-columns: 1fr;
  }

.grid--allcenter2 {
    justify-items: center;
}

.grid--1x2--special{
    grid-template-columns: 1fr 1fr; 
    column-gap: 20px;
    row-gap: 20px;
}

.grid--1x3{
    grid-template-columns: 1fr 1fr ;
}
  
  @media screen and (min-width: 768px) {
    .grid--1x2{
      grid-template-columns: 1fr 1fr;
    }

    .grid--2080{
        grid-template-columns: 20fr 80fr;  
    }

    .grid--1x4{
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        row-gap: 20px;
        column-gap: 20px;
    }
      

    
  }
  
  @media screen and (min-width: 1024px) {
    .grid--1x3{
      grid-template-columns: 1fr 1fr 1fr;
    }

    .grid--1x4{
        grid-template-columns: 1fr 1fr 1fr 1fr; 
    }
  
  }


  
