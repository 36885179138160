  /* ------------ Container ---------------------------*/

  
  .bluebox{
        height: 100px;
        width: 400px;
        border-radius: 6px;
        background-color: var(--color-lightblue);
        // box-shadow: 8px 8px 12px 0 rgba(83, 97, 255, 0.12),
        // -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
       margin: 20px;
        cursor: pointer;
        border-radius: 10px;
        // border: 2px solid #d2d1e9;
        cursor: pointer;
    
      }


     .bluebox--special {
      box-shadow: 10px 3px 12px 0 rgb(83 97 255 / 12%), inset -5px 20px 12px 0 rgb(255 255 255);
     }

     .codebox {
      font-size: 10px;
      position: relative;
      border: 2px solid #cfd7fd;;
      padding: 25px;
      border-radius: 5px;
      margin-bottom: 25px;
    }


  .whiteContainer{
// background: white;
outline: transparent solid 1px;
box-shadow: var(--cib-shadow-card);
background: #ffff;
padding: 30px;
margin: 20px;
width: 500px;
border-radius: 10px;

  }

  .developerComment {
    background: var(--color-lightblue);
    padding: 30px;
    border: 0.5px solid rgb(182, 168, 168);
  }

  
  .containerbox {
    background-color: var(--color-alternative);
    width: 100%;
    max-width: 600px;
    padding: 100px 50px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px;
    justify-content: space-around;
    padding: 10px;
  }
  
.profileHeader{
width:28%;
background-color: var(--color-alternative);
height: 100vh;

}



.stripebox {
  // margin: 100px;
  padding: 50px;
  width: 100%;

  height: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  flex-direction: column;
}

.pricingbox {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  position: relative;
}

.pricingcard {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 30px 15px 10px 10px;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  height:150px;
  cursor: pointer;
  border: 3px solid transparent;
  box-sizing: border-box;

}

.pricincard_list {
}


input.pricingbox_radio[type="radio"] {
  position: absolute;
  left: 30px;
  top: 30px;
}
input.pricingbox_radio[type="radio"]:checked + label {
  border: 3px solid var(--color-highlight);
}

.pricingbox_radio {
  opacity: 0;
  display: none;
}
  
@media screen and (min-width: 768px) {

  .pricingcard {
    max-width: "300px";
  }

 
  .pricingbox {
    flex-direction: row;
    height: auto;
  }

  .bluecontainer {
    width: 100%;
    max-width: 600px;
    border-radius: 6px;
    background-color: var(--color-lightblue);
    // background-image: linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 
    // 247) 
    // 100%);
    // box-shadow: 8px 8px 12px 0 rgba(83, 97, 255, 0.12),
    //     -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
    margin-bottom: 40px;
    padding: 90px;

  }

  .whiteContainer {
    background: white;
    border-radius: 25px;
  }


}


/*--------------Menu bar ---------------------------------------*/

// .bb {
//   border-bottom: 1px solid var(--color-blackover--special);
//   border-radius: 5px;
// }

.menuitem{
  padding: 20px;
  cursor: pointer;
    // font-weight: 700;
}

.menuitem__inactive {


}

.menuitem__active {
font-weight: 700;

// color: var( --color-highlight);

opacity: 0.8;
}


/*------Menu Bar -----------------------------*/

.menubar {

  // background: var(--color-fixedblack);
  height: 100vh;
  overflow: none;
  width: 200px;

}


/*-------- Top Bar ------------------------------*/

.topbar {
  background: var(--color-fixedblack);
  width: 1fr;
  height: "50px";
}

/* ------ White box ---------------------------*/

.whitebox {
  background: white;
  border-radius: 10px;
  height: 500px;
  margin: 20px;
  padding: 30px;
}

.smallwhitebox {
  background: white;
  margin: 20px;
  padding: 30px;
}

.leftborder {
border-left: 2px solid var(--color-highlight);
padding: 20px;
opacity: 60;
}





@media screen and (min-width: 768px) {

.grid--7030{
  grid-template-columns: 70fr 30fr;  
}

}





.homebox{
  // background: white;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  background-image: linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 
247) 
100%);
  padding: 50px;
border-radius: 5px;
width: 1000px;
margin: 50px;
}

/*--------Navbar ---------------------*/

.navbar {
  height: 60px;
  background: black;
  color: white;
  padding: 10px 30px;
}



.outlinebox {
  border: 1px solid rgb(173 155 155 / 24%);
  border-radius: 10px;
  margin: 30px;
  // box-shadow: 10px 3px 12px 0 rgb(83 97 255 / 12%), inset -5px 20px 12px 0 rgb(255 255 255);
  cursor: pointer;
  border-radius: 8px;

  
}

.projectbox {
  // border: 1px solid rgb(173 155 155 / 24%);
  padding: 5px 60px;
  margin: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  // background-color: #fff;
  cursor: pointer;
border-radius: 25px;

}

.instructionbox{
  background: white;
  box-shadow: 10px 3px 12px 0 rgb(83 97 255 / 12%), inset -5px 20px 12px 0 rgb(255 255 255);
  width: 400px;
  padding: 30px;
  height: 150px;
  border-radius: 10px;
}

.resultbox {
  border: none;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 60px;
  margin: 25px;
  // width: 100%;
  border-radius: 10px;
  width: 1000px;
  outline: 0;
  background: white;

  cursor: pointer;

}

.resultbox--movement {
  transition: all 0.3s ease;
  position: relative;
  opacity: 0.7;

}

.resultbox--movement:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 20px rgba(0,0,0,0.2);
  opacity: 1;

}


.resultbox--movement button {
  position: absolute;
   top: 1%;
  right: 1%;
}



/*------------- Table style -------------------------------------*/

.tableStyle{

  // background: var(--color-blackover);
  // color: var(--color-secondaryFont);
  font-size: 14px;
  border-radius: 15px;
  width: 100%;
  border-spacing: 0;
  text-align: left;

}

.newTableStyle{
  width: 70vw;
  font-size: 16px;
  border-radius: 15px;
  // width: 100%;
  border-spacing: 0;
  text-align: left;

}

.tableStyle th {
  padding: 15px 5px;
}

.newTableStyle th {
  padding: 15px 15px;
}


.newTableStyle tr  {
  padding: 15px 20px;
}



.tableStyle tr {

  padding: 15px 40px;

}

.newTableStyle tr {

  padding: 15px 40px;

}

.newTableStyle td{
padding: 15px 20px;
border-top: 1px solid var(--color-line);
text-align: left;
}

.tableStyle td {
  padding: 15px 0px;
border-top: 1px solid var(--color-line);
text-align: left;
}

th, td {

}

th:last-child, td:last-child {
  border-right: none;
}

.personContainer {
  padding:20px 20px;
  border-radius: 5px;
  width: 280px;
  margin: 10px 20px;
  border-bottom: 0.4px solid var(--color-line);
  cursor: pointer;
}

.personContainer:hover {
  border-bottom: 1px solid var(--color-line);
  font-weight: bold;
}

.personContainer--selected {
  border-bottom: 1px solid var(--color-line);
  font-weight: bold;
}


//*-------------------------Chatbot----------------------------------*//



.conversation {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  // padding: 30px;
  padding: 0px 30px;


  // background: #f9f9f9;
  white-space: pre-wrap;
  line-height: 1.7;
}

.conversation .operator-response {


  background-color: #3c7af1;
  color: white;
  padding: 15px 18px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  word-wrap: break-word;
  text-align: left;
  // margin-right:auto;
  box-sizing: border-box;
  clear: both;
  margin-bottom: 20px;
  margin-top: 20px;

   opacity: 90%;
}

.conversation .user-input {
   background-color: #f4f6ff;
  // background-color: white;
  color: #333;
  padding: 15px 18px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  word-wrap: break-word;
  text-align: left;
  // margin-left:auto;
  box-sizing: border-box;
  box-shadow: 8px 8px 12px 0 rgba(83, 97, 255, 0.12),
  -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
  clear: both;
  // align-self: flex-end; /* added */

}

.chatblock {
  position: fixed;
  bottom: 10px;
}






// @media screen and (min-width: 768px) {
//   .chatbox {
//     width: 380px;
//   }
// }


@media screen and (min-height: 600px) {
  .chatbox {
    height: 100%;
  }
}

/*------------- Grid Container -----------------------------*/

.gridcontainer {
    // display: grid;
    width: 20%;

}

/*------------ Pricing card --------------------------------*/@at-root

.pricing_box {
  background: white;
  padding: 20px 20px;
  border-radius: 12px 8px;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.05);
  height: 650px;
width: 300px;

  }
  
  .pricing_box button {
    position: absolute;
    bottom: 10px;
  }



  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  @media screen and (min-width: 1024px) {
    .desktop {
    display: block;
    }

    .mobile {
      display: none;
    }
  }
  

/*------------------ Color --------------------------------*/
.colorbox {
  border-radius: 50%; /* This will make the input round if it's square */
  /* Additional styles to ensure the color box looks good as a circle */
  width: 30px; /* or the size you want */
  height: 30px; /* should match the width to be a perfect circle */
  border: none; /* optional, removes the border */
  padding: 0; /* optional, removes the padding */
  -webkit-appearance: none; /* removes default styling on WebKit browsers */
  cursor: pointer; /* optional, changes the cursor to indicate it's clickable */
}

/* To target the color well inside the input */
.colorbox::-webkit-color-swatch-wrapper {
  padding: 0;
}

.colorbox::-webkit-color-swatch {
  border: 0.1px solid black;
}

.selectionbox{
  background: white;
  padding: 30px;
  min-height: 200px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08);
  cursor: pointer;
}

.selectionbox_selected{
border-bottom: 12px solid cornflowerblue;
}

.black {
  background: black;
}

/*-------- This item is for Block tabs -------------------*/

.bordered-container {
  border: 0.01px solid var(--color-line);
  padding: 50px;
  border-radius: 5px;
}

.title {
  border-bottom: 0.01px solid var(--color-line);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.actionbox{
  width: 1fr;
  background: whitesmoke;
  padding: 10px 20px;
  margin: 20px;
  border-radius: 10px;
}

/*-------------- Menu bar --------------------------*/

.navbar-container {
  width: 20vw;
  background-color: #f5f5f5;
  padding: 20px;
  height: 100vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  position:fixed;
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.brandlogo {
  font-size: 24px;
  font-weight: bold;
  margin: 15px 20px;
}

.navbar-links {
  display: flex;
  flex-direction: column;
}

.link-item {
  display: flex;
  align-items: center;
  margin: 15px 25px;
  font-weight: 600;
  cursor: pointer;
  font-size: larger;
}

.link-item span {
  margin-left: 10px;
}

/* Additional active state styling */
.link-item.active {
  background-color: #000000; /* Change this to your desired active state background color */
  border-radius: 8px;
  color: white;
  padding: 5px 12px;
  font-size: 20px;
}


/*--------- React File Zone file upload --------------------------------*/

.file-upload-container {
  width: 400px;
  height: 300px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}

.expanded {
  width: 500px;
  height: 400px;
  padding: 30px; /* Increased by 10px to maintain the internal content position */
}

.dropzone {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropzone p {
  margin: 0;
  padding: 0;
}

.browse-button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.browse-button:hover {
  background-color: #0056b3;
}


/*-------------- Draggleblock ----------------------------------*/

.movecards {
  background: white;
  width: 800px;
  // padding: 30px;
  margin: 50px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
padding: 10px 50px;
// margin: 25px;
// width: 100%;
border-radius: 20px 20px;
}

.movecards--invisible {
  display: none;
}

/*-------- Toggle block -----------------------*/

.toggleblock {
  background: aliceblue;
    padding: 15px;
    border-radius: 10px;
}